import { createMedia } from '@artsy/fresnel'

export const breakpoints = {
  xsmall: 0,
  small: 576,
  medium: 768,
  large: 992,
  largeTablet: 1025,
  xlarge: 1200,
  xxlarge: 1600,
}

const BreakpointMedia = createMedia({ breakpoints })

// Generate CSS to be injected into the head
export const mediaStyle = BreakpointMedia.createMediaStyle()
export const { Media, MediaContextProvider } = BreakpointMedia
