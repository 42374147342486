export enum Product {
  READ_MORE = 'Product.READ_MORE',
  BUNDLING_SCROLL = 'Product.BUNDLING_SCROLL',
  BUNDLING_TRAY_OPEN = 'Product.BUNDLING_TRAY_OPEN',
  PRODUCT_ICON_LINK_CLICK = 'Product.PRODUCT_ICON_LINK_CLICK',
  IN_STORE_AVAILABILITY_DETAILS_CLICK = 'Product.IN_STORE_AVAILABILITY_DETAILS_CLICK',
  IN_STORE_AVAILABILITY_OTHER_STORES_CLICK = 'Product.IN_STORE_AVAILABILITY_OTHER_STORES_CLICK',
  IN_STORE_AVAILABILITY_STOREFRONT_CLICK_TEXT = 'Product.IN_STORE_AVAILABILITY_STOREFRONT_CLICK_TEXT',
  IN_STORE_AVAILABILITY_STOREFRONT_CLICK_AVAILABLE = 'Product.IN_STORE_AVAILABILITY_STOREFRONT_CLICK_AVAILABLE',
  IN_STORE_AVAILABILITY_STOREFRONT_CLICK_UNAVAILABLE = 'Product.IN_STORE_AVAILABILITY_STOREFRONT_CLICK_UNAVAILABLE',
  MODEL_VIEW = 'Product.MODEL_VIEW',
  MODEL_CHANGE = 'Product.MODEL_CHANGE',
  WAITLIST_INTENT = 'Product.WAITLIST_INTENT',
  WAITLIST_SUCCESSFUL = 'Product.WAITLIST_SUCCESSFUL',
  TRAY_CLOSE = 'Product.TRAY_CLOSE',
  TRAY_OPEN = 'Product.TRAY_OPEN',
  FACTORY_CLICK = 'Product.FACTORY_CLICK',
  CONTACT_US_CLICK = 'Product.CONTACT_US_CLICK',
  ALSO_AVAILABLE_IN_CLICK = 'Product.ALSO_AVAILABLE_IN_CLICK',
  ASSET_SWIPE = 'Product.ASSET_SWIPE',
  COLOR_CHANGE = 'Product.COLOR_CHANGE',
  CHANGE_FIT_OR_MATERIAL_OPTION = 'Product.CHANGE_FIT_OR_MATERIAL_OPTION',
  REVIEW_PAGINATION = 'Product.REVIEW_PAGINATION',
  RECOMMENDATIONS_CAROUSEL_VIEW = 'Product.RECOMMENDATIONS_CAROUSEL_VIEW',
  SIZE_CHANGE = 'Product.SIZE_CHANGE',
  SIMILAR_PRODUCT = 'Product.SIMILAR_PRODUCT',
  SIMILAR_PRODUCTS_SCROLL = 'Product.SIMILAR_PRODUCTS_SCROLL',
  SINGLE_PRODUCT_CALLOUT_CLICK = 'Product.SINGLE_PRODUCT_CALLOUT_CLICK',
  SIZE_GUIDE_OPEN = 'Product.SIZE_GUIDE_OPEN',
  SIZE_GUIDE_CLOSE = 'Product.SIZE_GUIDE_CLOSE',
  SIZE_GUIDE_CHANGE_SIZE = 'Product.SIZE_GUIDE_CHANGE_SIZE',
  SIZE_GUIDE_CHANGE_UNIT = 'Product.SIZE_GUIDE_CHANGE_UNIT',
  SIZE_GUIDE_CHANGE_TAB = 'Product.SIZE_GUIDE_CHANGE_TAB',
  PRODUCT_DETAILS_TAB_CHANGE = 'Product.PRODUCT_DETAILS_TAB_CHANGE',
  PAGE_VIEW = 'Product.PAGE_VIEW',
  SIZE_SCROLL = 'Product.SIZE_SCROLL',
  SEE_REVIEWS = 'Product.SEE_REVIEWS',
  MODEL_IS_WEARING = 'Product.MODEL_IS_WEARING',
  REVIEW_ADD_FILTER = 'Product.REVIEW_ADD_FILTER',
  REVIEW_SORT = 'Product.REVIEW_SORT',
  BREADCRUMB = 'Product.BREADCRUMB',
  UGC_WIDGET_CLICK = 'Product.UGC_WIDGET_CLICK',
  VIEW_FULL_DETAILS_CLICKED = 'Product.VIEW_FULL_DETAILS_CLICKED',
  MORE_TO_EXPLORE_CLICKED = 'Product.MORE_TO_EXPLORE_CLICKED',
  IMAGE_CAROUSEL_CLICK = 'Product.IMAGE_CAROUSEL_CLICK',
  SIMILAR_STYLE_CLICKED = 'Product.SIMILAR_STYLE_CLICKED',
  DESKTOP_GALLERY_IMAGE_SCROLL = 'Product.DESKTOP_GALLERY_IMAGE_SCROLL',
  DESKTOP_GALLERY_IMAGE_ZOOM = 'Product.DESKTOP_GALLERY_IMAGE_ZOOM',
  DESKTOP_GALLERY_THUMBNAIL_CLICK = 'Product.DESKTOP_GALLERY_THUMBNAIL_CLICK',
  MANUAL_PRODUCT_RECOMMENDATION_CLICK = 'Product.MANUAL_PRODUCT_RECOMMENDATION_CLICK',
  ALGOLIA_PRODUCT_RECOMMENDATION_CLICK = 'Product.ALGOLIA_PRODUCT_RECOMMENDATION_CLICK',
  OVERLAY_MODAL_VIEW = 'Product.OVERLAY_MODAL_VIEW',
  MODAL_ADD_TO_CART_CLICK = 'Product.MODAL_ADD_TO_CART_CLICK',
  MODAL_PREORDER_ADD_TO_CART_CLICK = 'Product.MODAL_PREORDER_ADD_TO_CART_CLICK',
  MODAL_WAITLIST_CLICK = 'Product.MODAL_WAITLIST_CLICK',
  MODAL_FULL_DETAILS_CLICK = 'Product.MODAL_FULL_DETAILS_CLICK',
  OVERLAY_MODAL_CLOSE = 'Product.OVERLAY_MODAL_CLOSE',
  LIVE_CHAT_CLICK = 'Product.LIVE_CHAT_CLICK',
  TITLE_SEQ_VIEW = 'Product.TITLE_SEQ_VIEW',
  TITLE_SEQ_EXIT = 'Product.TITLE_SEQ_EXIT',
  TITLE_SEQ_UGC_CLICK = 'Product.TITLE_SEQ_UGC_CLICK', // trigged in builder
  TITLE_SEQ_MORETOLOVE_CLICK = 'Product.TITLE_SEQ_MORETOLOVE_CLICK', // trigged in builder
  TITLE_SEQ_CTA_CLICK = 'Product.TITLE_SEQ_CTA_CLICK', // trigged in builder
  TITLE_SEQ_OPEN_CLICK = 'Product.TITLE_SEQ_OPEN_CLICK',
  EMAIL_LIST_MODAL_CTA_CLICK = 'Product.EMAIL_LIST_MODAL_CTA_CLICK', // triggered in builder
  EMAIL_LIST_MODAL_VIEW = 'Product.EMAIL_LIST_MODAL_VIEW',
}

export default Product
