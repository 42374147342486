import Bugsnag from '@bugsnag/js'
import useSWR from 'swr'

import ApiError from 'lib/network/ApiError'
import { get } from 'lib/network/fetchers/everlane'
import { setUserEmail } from 'lib/helpers/wunderkind'
import stripPII from 'lib/stripPII'
import Session from 'types/Session'

const useSession = () => {
  const { data: session, ...result } = useSWR<Session, ApiError>(
    '/api/v2/session',
    path => get(path),
    { revalidateIfStale: false, revalidateOnFocus: false },
  )

  if (session?.visitor) {
    Bugsnag.addMetadata('session', stripPII(session))
  }

  // wunderkind
  if (session?.user) {
    setUserEmail(session.user.email)
  }

  return { ...result, data: session }
}

export default useSession
