import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import ApiError from './ApiError'

const deepFormatKeys = <T = any>(data: any, formatter) => {
  if (isArray(data)) {
    return data.map(item => deepFormatKeys(item, formatter))
  }

  if (isObject(data)) {
    return mapValues(
      mapKeys(data, (_value, key) => formatter(key)),
      o => deepFormatKeys(o, formatter),
    )
  }

  return data as T
}

export const deepCamelCase = data => deepFormatKeys(data, camelCase)
export const deepSnakeCase = data => deepFormatKeys(data, snakeCase)

export const handleResponse = async <T>(response: Response): Promise<T> => {
  // response.json() will error on a null body
  const text = await response.text()
  const data = text.length ? JSON.parse(text) : {}

  if (!response.ok) {
    throw new ApiError(response.status, response.statusText, deepCamelCase(data))
  }

  if (response.statusText === 'No Content') {
    return null
  }

  return deepCamelCase(data) as T
}
