export enum SmsLogin {
  LOG_IN_ERROR = 'SmsLogin.LOG_IN_ERROR',
  LOG_IN_CLOSE_MODAL = 'SmsLogin.LOG_IN_CLOSE_MODAL',
  LOG_IN_SHOW_MODAL = 'SmsLogin.LOG_IN_SHOW_MODAL',
  LOG_IN_RESEND = 'SmsLogin.LOG_IN_RESEND',
  LOG_IN_SUCCESS = 'SmsLogin.LOG_IN_SUCCESS',
  LOG_IN_SUBMIT = 'SmsLogin.LOG_IN_SUBMIT',
  OPT_IN_CLOSE_MODAL = 'SmsLogin.OPT_IN_CLOSE_MODAL',
  OPT_IN_SHOW_MODAL = 'SmsLogin.OPT_IN_SHOW_MODAL',
  OPT_IN_SUCCESS = 'SmsLogin.OPT_IN_SUCCESS',
  OPT_OUT_SUCCESS = 'SmsLogin.OPT_OUT_SUCCESS',
  OPT_IN_SUBMIT = 'SmsLogin.OPT_IN_SUBMIT',
}

export default SmsLogin
