export enum Checkout {
  ADD_ADDRESS = 'Checkout.ADD_ADDRESS',
  ADD_CREDIT_CARD = 'Checkout.ADD_CREDIT_CARD',
  ADD_TO_CART = 'Checkout.ADD_TO_CART',
  BEGIN_AFTERPAY = 'Checkout.BEGIN_AFTERPAY',
  BEGIN_REACH_PAYPAL = 'Checkout.BEGIN_REACH_PAYPAL',
  BEGIN_REACH_KLARNA = 'Checkout.BEGIN_REACH_KLARNA',
  BILLING_VIEW = 'Checkout.BILLING_VIEW',
  CANCEL_REACH_KLARNA = 'Checkout.CANCEL_REACH_KLARNA',
  CANCEL_ORDER = 'Checkout.CANCEL_ORDER',
  CHANGE_TO_EXISTING_ADDRESS = 'Checkout.CHANGE_TO_EXISTING_ADDRESS',
  CHANGE_SHIPPING_ADDRESS = 'Checkout.CHANGE_SHIPPING_ADDRESS',
  CHANGE_PRODUCT_QUANTITY = 'Checkout.CHANGE_PRODUCT_QUANTITY',
  COMPLETE = 'Checkout.COMPLETE',
  COMPLETE_REACH_KLARNA = 'Checkout.COMPLETE_REACH_KLARNA',
  CONTINUE_TO_CHECKOUT_CLICK = 'Checkout.CONTINUE_TO_CHECKOUT_CLICK',
  CONTINUE_TO_SHIPPING_OPTIONS_BUTTON_CLICK = 'Checkout.CONTINUE_TO_SHIPPING_OPTIONS_BUTTON_CLICK',
  CREATE_ACCOUNT_CTA_CLICK = 'Checkout.CREATE_ACCOUNT_CTA_CLICK',
  EMAIL_OPT_OUT = 'Checkout.EMAIL_OPT_OUT',
  FULL_ACCOUNT_GUEST_CHECKOUT_CLICK = 'Checkout.FULL_ACCOUNT_GUEST_CHECKOUT_CLICK',
  PAGE_VIEW = 'Checkout.PAGE_VIEW',
  PLACE_ORDER = 'Checkout.PLACE_ORDER',
  PRODUCT_UPSELL_ATC = 'Checkout.PRODUCT_UPSELL_ATC',
  CART_PRODUCT_RECOMMENDATION_CLICK = 'Checkout.CART_PRODUCT_RECOMMENDATION_CLICK',
  CART_ALGOLIA_PRODUCT_RECOMMENDATION_CLICK = 'Checkout.CART_ALGOLIA_PRODUCT_RECOMMENDATION_CLICK',
  CART_ALGOLIA_CAROUSEL_SCROLL = 'Checkout.CART_ALGOLIA_CAROUSEL_SCROLL',
  CART_CAROUSEL_SCROLL = 'Checkout.CART_CAROUSEL_SCROLL',
  REMOVE_ITEM = 'Checkout.REMOVE_ITEM',
  REVIEW_ORDER_BUTTON_CLICK = 'Checkout.REVIEW_ORDER_BUTTON_CLICK',
  SEE_SAVED_PAYMENTS = 'Checkout.SEE_SAVED_PAYMENTS',
  SHIPPING_OPTION_CHANGED = 'Checkout.SHIPPING_OPTION_CHANGED',
  SHIPPING_VIEW = 'Checkout.SHIPPING_VIEW',
  SUBMIT_ADDRESS = 'Checkout.SUBMIT_ADDRESS',
  SUBMIT_CREDIT_CARD = 'Checkout.SUBMIT_CREDIT_CARD',
  SUBMIT_CREDIT_CARD_ERROR = 'Checkout.SUBMIT_CREDIT_CARD_ERROR',
  TAX_ID_VALIDITY = 'Checkout.TAX_ID_VALIDITY',
  THANKS_PAGE_SMS_CLICK = 'Checkout.THANKS_PAGE_SMS_CLICK',
}

export enum ApplePay {
  STARTED = 'Checkout.ApplePay.STARTED',
  COMPLETED = 'Checkout.ApplePay.COMPLETED',
}

export enum ApplePayPDPAuth {
  STARTED = 'Checkout.ApplePayPDPAuth.STARTED',
  COMPLETED = 'Checkout.ApplePayPDPAuth.COMPLETED',
}

export enum ApplePayExpress {
  STARTED = 'Checkout.ApplePayExpress.STARTED',
  COMPLETED = 'Checkout.ApplePayExpress.COMPLETED',
}

export enum ApplePayExpressCart {
  STARTED = 'Checkout.ApplePayExpressCart.STARTED',
  COMPLETED = 'Checkout.ApplePayExpressCart.COMPLETED',
}

export enum Cvc {
  REAUTH_REQUIRED = 'Checkout.Cvc.REAUTH_REQUIRED',
  REAUTH_SUBMIT = 'Checkout.Cvc.REAUTH_SUBMIT',
}

export enum GooglePay {
  STARTED = 'Checkout.GooglePay.STARTED',
  COMPLETED = 'Checkout.GooglePay.COMPLETED',
}

export enum GooglePayExpress {
  STARTED = 'Checkout.GooglePayExpress.STARTED',
  COMPLETED = 'Checkout.GooglePayExpress.COMPLETED',
}

export enum GooglePayExpressCart {
  STARTED = 'Checkout.GooglePayExpressCart.STARTED',
  COMPLETED = 'Checkout.GooglePayExpressCart.COMPLETED',
}

export enum CarbonOffset {
  INFO_CLICK = 'Checkout.CarbonOffset.INFO_CLICK',
  CHECKBOX_CLICK = 'Checkout.CarbonOffset.CHECKBOX_CLICK',
}

export enum PaypalAuth {
  STARTED = 'Checkout.PaypalAuth.STARTED',
  COMPLETED = 'Checkout.PaypalAuth.COMPLETED',
  CANCELLED = 'Checkout.PaypalAuth.CANCELLED',
  BLOCKED_BY_CLIENT = 'Checkout.PaypalAuth.BLOCKED_BY_CLIENT',
  STARTED_EXPRESS = 'Checkout.PaypalAuthExpress.STARTED',
  COMPLETED_EXPRESS = 'Checkout.PaypalAuthExpress.COMPLETED',
  CANCELLED_EXPRESS = 'Checkout.PaypalAuthExpress.CANCELLED',
  BLOCKED_BY_CLIENT_EXPRESS = 'Checkout.PaypalAuthExpress.BLOCKED_BY_CLIENT',
}

export enum ShopPay {
  STARTED = 'Checkout.ShopPay.STARTED',
  COMPLETED = 'Checkout.ShopPay.COMPLETED',
}

export enum ReachChallenge {
  STARTED = 'Checkout.ReachChallenge.STARTED',
  COMPLETED = 'Checkout.ReachChallenge.COMPLETED',
  FAILED = 'Checkout.ReachChallenge.FAILED',
}

const Event = {
  ...Checkout,
  ApplePay,
  ApplePayPDPAuth,
  ApplePayExpress,
  ApplePayExpressCart,
  GooglePay,
  GooglePayExpress,
  GooglePayExpressCart,
  CarbonOffset,
  PaypalAuth,
  ReachChallenge,
  Cvc,
  ShopPay,
}

export default Event
