import AccountEvents from './Account'
import AddressEvents from './Address'
import AlgoliaSearchEvents from './AlgoliaSearch'
import AppEvents from './App'
import AttentiveEvents from './Attentive'
import CheckoutEvents from './Checkout'
import CollectionsEvents from './Collections'
import FeedEvents from './Feed'
import GiftcardsEvents from './Giftcards'
import HeaderEvents from './Header'
import HomeEvents from './Home'
import HomepageEvents from './Homepage'
import InviteEvents from './Invite'
import InviteFormEvents from './InviteForm'
import LoginEvents from './Login'
import MobileEvents from './Mobile'
import NavigationEvents from './Navigation'
import OneTrustEvents from './OneTrust'
import PaidMarketingEvents from './PaidMarketing'
import PageEvents from './Page'
import ProductEvents from './Product'
import ScrollEvents from './Scroll'
import SizeTrayEvents from './SizeTray'
import SmsLoginEvents from './SmsLogin'
import SustainabilityEvents from './Sustainability'
import UserEvents from './User'
import WunderkindEvents from './Wunderkind'
import KustomerEvents from './Kustomer'

export enum Base {
  AB_TEST_TREATMENT_SET = 'AB_TEST_TREATMENT_SET',
  GTM_LOADED = 'GTM_LOADED',
}

export const event = {
  ...Base,
  Account: AccountEvents,
  Address: AddressEvents,
  AlgoliaSearch: AlgoliaSearchEvents,
  App: AppEvents,
  Attentive: AttentiveEvents,
  Checkout: CheckoutEvents,
  Collections: CollectionsEvents,
  Feed: FeedEvents,
  Giftcards: GiftcardsEvents,
  Header: HeaderEvents,
  Home: HomeEvents,
  Homepage: HomepageEvents,
  Invite: InviteEvents,
  InviteForm: InviteFormEvents,
  Login: LoginEvents,
  Mobile: MobileEvents,
  Navigation: NavigationEvents,
  OneTrust: OneTrustEvents,
  Page: PageEvents,
  PaidMarketing: PaidMarketingEvents,
  Product: ProductEvents,
  Scroll: ScrollEvents,
  SizeTray: SizeTrayEvents,
  SmsLogin: SmsLoginEvents,
  Sustainability: SustainabilityEvents,
  User: UserEvents,
  Wunderkind: WunderkindEvents,
  Kustomer: KustomerEvents,
}

export default event
