import deepOmit from 'omit-deep-lodash'

const stripPII = object =>
  deepOmit(
    object,
    'email',
    'gender',
    'firstName',
    'lastName',
    'userFirstName',
    'fullName',
    'addresses',
  )

export default stripPII
