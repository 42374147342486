/* eslint-disable camelcase */

import { EventProperties, FilteredEvent, PublishedProperties } from 'types/WebClientEvent'
import Event from 'types/Event'

type FilterMap = {
  [key in FilteredEvent['event']]?: (
    data: EventProperties<FilteredEvent, key>,
  ) => PublishedProperties<FilteredEvent, key>
}

// This function shapes the event properties that ultimately go into Redshift
// It's important that we send primitives. Don't send large objects like entire collections
// because it hits payload size limits.
const propertyFilter = <T extends FilteredEvent>(
  eventName: string,
  eventPayload: EventProperties<FilteredEvent, T['event']>,
) => {
  const filterMap: FilterMap = {
    [Event.Collections.PAGE_VIEW]: eventData => {
      const { permalink } = eventData
      return {
        permalink,
      }
    },
    [Event.Checkout.ADD_TO_CART]: eventData => {
      // collection must be a collection permalink.
      const { product, variant_id, location, bundle_item, collection } = eventData
      const { displayName, usdPrice, id } = product

      return {
        variant_id,
        location,
        bundle_item,
        collection,
        product_name: displayName,
        product_price: usdPrice,
        product_id: id,
      }
    },
    [Event.Product.PAGE_VIEW]: eventData => {
      // collection must be a collection permalink.
      const { product, product_id, permalink, collection } = eventData
      const { displayName, usdPrice } = product
      return {
        product_id,
        permalink,
        collection,
        product_name: displayName,
        product_price: usdPrice,
      }
    },
    [Event.AlgoliaSearch.PRODUCT_VIEW]: eventData => {
      const { query, queryID, position, productID } = eventData
      return {
        query,
        queryID,
        position,
        product_id: productID,
      }
    },
  }

  if (filterMap[eventName]) {
    return filterMap[eventName](eventPayload)
  }
  return eventPayload
}

export default propertyFilter
