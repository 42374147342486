import getConfig from 'next/config'
import Cookie from 'js-cookie'
import debounce from 'lodash/debounce'
import Cookies from 'types/Cookies'
import { handleResponse, deepSnakeCase } from '../utils'
import { Params, FetchParams } from '../types'

function apiRoot() {
  if (process.env.NEXT_PUBLIC_API_ROOT) return process.env.NEXT_PUBLIC_API_ROOT
  if (typeof window !== 'undefined') return window.location.href
  throw new Error('API_ROOT is undefined')
}

const apiUrl = (path: string, params = {}) => {
  const url = new URL(path, apiRoot())

  // useSWR doesn't support separate params, so those uses pass query-params
  // in the path; we don't want to override them
  const queryString = new URLSearchParams(params).toString()
  if (queryString) {
    url.search = new URLSearchParams(params).toString()
  }

  return url.toString()
}

export const csrfToken = () => {
  return Cookie.get(Cookies.CSRF_TOKEN) as string
}

// TODO: refactor to remove publicRuntimeConfig.
const { EVERLANE_CLIENT } = getConfig().publicRuntimeConfig

export const configureCsrfToken = debounce(
  async () => {
    if (!global.window || csrfToken()) return

    await fetch(apiUrl('/api/v2/configure_csrf_token'), {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Everlane-Client': EVERLANE_CLIENT,
      },
    })
  },
  2000,
  { leading: true, trailing: false },
)

export const get = async <T = any, P = Params>(
  path: string,
  params: P = {} as P,
  fetchParams: FetchParams = { headers: {}, mode: 'cors', credentials: 'include' },
) => {
  const response = await fetch(apiUrl(path, deepSnakeCase(params)), {
    ...fetchParams,
    credentials: fetchParams.credentials,
    headers: {
      ...fetchParams.headers,
      Cookie: !global.window ? `everlane-trusted-client=${process.env.TRUSTED_CLIENT_TOKEN}` : null,
      'Everlane-Client': EVERLANE_CLIENT,
    },
  })

  return handleResponse<T>(response)
}

export const post = async <T = any, P = Params>(
  path: string,
  params: P = {} as P,
  fetchParams: FetchParams = { headers: {}, mode: 'cors' },
  convertToSnakeCase: boolean = true,
) => {
  await configureCsrfToken()

  const response = await fetch(apiUrl(path), {
    ...fetchParams,
    method: 'POST',
    body: JSON.stringify(convertToSnakeCase ? deepSnakeCase(params) : params),
    credentials: 'include',
    headers: {
      ...fetchParams.headers,
      'Content-Type': 'application/json',
      'Everlane-Client': EVERLANE_CLIENT,
      'X-CSRF-Token': csrfToken(),
    },
  })

  return handleResponse<T>(response)
}

export const patch = async <T = any, P = Params>(
  path: string,
  params: P = {} as P,
  fetchParams: FetchParams = { headers: {}, mode: 'cors' },
) => {
  await configureCsrfToken()

  const response = await fetch(apiUrl(path), {
    ...fetchParams,
    method: 'PATCH',
    body: JSON.stringify(deepSnakeCase(params)),
    credentials: 'include',
    headers: {
      ...fetchParams.headers,
      'Content-Type': 'application/json',
      'Everlane-Client': EVERLANE_CLIENT,
      'X-CSRF-Token': csrfToken(),
    },
  })

  return handleResponse<T>(response)
}

export const put = async <T = any, P = Params>(
  path: string,
  params: P = {} as P,
  fetchParams: FetchParams = { headers: {}, mode: 'cors' },
) => {
  await configureCsrfToken()

  const response = await fetch(apiUrl(path), {
    ...fetchParams,
    method: 'PUT',
    body: JSON.stringify(deepSnakeCase(params)),
    credentials: 'include',
    headers: {
      ...fetchParams.headers,
      'Content-Type': 'application/json',
      'Everlane-Client': EVERLANE_CLIENT,
      'X-CSRF-Token': csrfToken(),
    },
  })

  return handleResponse<T>(response)
}
