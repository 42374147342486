export enum Login {
  SHOW_SIGN_IN_VIEW = 'Login.SHOW_SIGN_IN_VIEW',
  SHOW_SIGN_UP_VIEW = 'Login.SHOW_SIGN_UP_VIEW',
  SHOW_RESET_PASSWORD = 'Login.SHOW_RESET_PASSWORD',
  SUBMIT_RESET_PASSWORD = 'Login.SUBMIT_RESET_PASSWORD',
  SWITCH_TO_SIGN_IN = 'Login.SWITCH_TO_SIGN_IN',
  SWITCH_TO_SIGN_UP = 'Login.SWITCH_TO_SIGN_UP',
  EMAIL_ENTERED = 'Login.EMAIL_ENTERED',
  MODAL_VIEW = 'Login.MODAL_VIEW',
  MODAL_CLOSED = 'Login.MODAL_CLOSED',
  FACEBOOK_BUTTON = 'Login.FACEBOOK_BUTTON',
  GOOGLE_BUTTON = 'Login.GOOGLE_BUTTON',
  EMAIL_PW = 'Login.EMAIL_EMAIL_PW',
  TRY_ANOTHER_EMAIL_CLICKED = 'Login.TRY_ANOTHER_EMAIL_CLICKED',
}

export default Login
