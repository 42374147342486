import Bugsnag from '@bugsnag/js'

const DEFAULT_META_NAME = 'Additional Metadata'
const ORIGINAL_ERROR_META_NAME = 'Original Error'
const ignoreErrorMessages = ['Failed to fetch', 'cancelled', 'The network connection was lost.']
const ignoreErrorCodes = ['PAYPAL_POPUP_CLOSED']

type NotifyParams = {
  metadata?: object
  metadataName?: string
  originalError?: any
}

const notify = (
  error: Error,
  { metadata, metadataName = DEFAULT_META_NAME, originalError = error }: NotifyParams = {},
) => {
  const { message = '', code = '' } = originalError || {}
  if (ignoreErrorMessages.includes(message) || ignoreErrorCodes.includes(code)) return

  Bugsnag.notify(error, event => {
    if (originalError) event.addMetadata(ORIGINAL_ERROR_META_NAME, originalError)
    if (metadata) event.addMetadata(metadataName, metadata)
  })
}

export default notify
