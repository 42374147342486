class ApiError extends Error {
  responseJSON: any

  code: string

  status: number

  statusText: string

  message: string

  constructor(status: number, statusText: string, responseJSON: any) {
    const message = responseJSON?.message || `${statusText} (${status})`

    super(message)

    this.name = 'ApiError'
    this.code = responseJSON?.code || status.toString()
    this.status = status
    this.statusText = statusText
    this.message = message
    this.responseJSON = responseJSON
  }
}

export default ApiError
