/* eslint-disable no-console */

const formatBugsnagLog = message => {
  return `[Bugsnag] ${message}`
}

const BugsnagLogger = {
  // eslint-disable-next-line lodash/prefer-noop
  debug: () => {},
  warn: m => console.warn(formatBugsnagLog(m)),
  info: m => console.info(formatBugsnagLog(m)),
  error: m => console.error(formatBugsnagLog(m)),
}

export default BugsnagLogger
