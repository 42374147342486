declare global {
  interface Window {
    wunderkind: {
      cart: any
      category?: any
      product?: any
      user: any
      visitor?: any
    }
  }
}

// initializes wunderkind object and sets values
export const initializeWunderkindForCollection = visitor => {
  if (global?.window) {
    global.window.wunderkind = {
      cart: {},
      user: {},
      category: {},
      visitor: { session_id: visitor?.sessionId },
    }
  }
}

export const initializeWunderkindForPDP = visitor => {
  if (global?.window) {
    global.window.wunderkind = {
      cart: {},
      product: {},
      user: {},
      visitor: { session_id: visitor?.sessionId },
    }
  }
}

export const initializeWunderkind = visitor => {
  if (global?.window) {
    global.window.wunderkind = {
      cart: {},
      user: {},
      visitor: { session_id: visitor?.sessionId },
    }
  }
}

export const setUserEmail = email => {
  if (global?.window?.wunderkind) {
    global.window.wunderkind.user.email = email
  }
}

export const setCartValue = value => {
  if (global?.window?.wunderkind) {
    global.window.wunderkind.cart.value = value
  }
}

export const setCartQty = qty => {
  if (global?.window?.wunderkind) {
    global.window.wunderkind.cart.quantity = qty
  }
}

export const setProductInfo = ({ id, sku, name, url, inStock, imgUrl, itemCategory }) => {
  if (global?.window?.wunderkind) {
    global.window.wunderkind.product = {
      id,
      sku,
      name,
      url,
      inStock,
      imgUrl,
      itemCategory,
    }
  }
}

export const setCategoryInfo = ({ title, ids }) => {
  if (global?.window?.wunderkind) {
    global.window.wunderkind.category = {
      title,
      ids,
    }
  }
}
