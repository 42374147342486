import Event from 'types/Event'
import WebClientEvent from 'types/WebClientEvent'

type ExtractAutoTrackEvent<A> = A extends { autoTrack: boolean } ? A : never
type AutoTrackEvent = ExtractAutoTrackEvent<WebClientEvent>
type AutoTrack<A, T> = A extends { event: T; autoTrack: boolean } ? A['autoTrack'] : true

type AutoTrackMap = {
  [key in AutoTrackEvent['event']]: AutoTrack<AutoTrackEvent, key>
}

const autoTrack: AutoTrackMap = {
  [Event.GTM_LOADED]: false,
  [Event.User.SIGN_IN]: false,
  [Event.User.SIGN_UP]: false,
  [Event.Checkout.COMPLETE]: false,
  [Event.App.POST_CRITICAL]: false,
  [Event.Collections.PRODUCT_HOVER]: false,
  [Event.Collections.COLOR_HOVER]: false,
}

export default autoTrack
