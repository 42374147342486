export enum AlgoliaSearch {
  PAGE_VIEW = 'AlgoliaSearch.PAGE_VIEW',
  CLEAR_QUERY = 'AlgoliaSearch.CLEAR_QUERY',
  CLOSE = 'AlgoliaSearch.CLOSE',
  FOCUS_INPUT = 'AlgoliaSearch.FOCUS_INPUT',
  NAV_CLICK = 'AlgoliaSearch.NAV_CLICK',
  PRODUCT_VIEW = 'AlgoliaSearch.PRODUCT_VIEW',
  SEARCHING = 'AlgoliaSearch.SEARCHING',
  SHOW_MORE = 'AlgoliaSearch.SHOW_MORE',
  FILTER_CLICK = 'AlgoliaSearch.FILTER_CLICK',
  FILTER_APPLY = 'AlgoliaSearch.FILTER_APPLY',
}

export default AlgoliaSearch
